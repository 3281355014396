import { createApp } from 'vue'
import { createPinia } from 'pinia'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'
import { createRouterScroller } from 'vue-router-better-scroller'
import App from './App.vue'
import router from './router'

import './app.less'
import './assets/icon/iconfont.css'

// import I18n from "@/lang/i18n"
import I18NextVue from 'i18next-vue'
import { createI18n } from './lang/i18n'

// Vant 桌面端适配
import '@vant/touch-emulator'

/* --------------------------------
Vant 中有个别组件是以函数的形式提供的，
包括 Toast，Dialog，Notify 和 ImagePreview 组件。
在使用函数组件时，unplugin-vue-components
无法自动引入对应的样式，因此需要手动引入样式。
------------------------------------- */
import 'vant/es/toast/style'
import 'vant/es/dialog/style'
import 'vant/es/notify/style'
import 'vant/es/image-preview/style'
import { brand } from './api/config'

const app = createApp(App)
const pinia = createPinia()
pinia.use(piniaPluginPersistedstate)
app.use(router)
app.use(pinia)

const getURLParameters = (url) =>
	(url.match(/([^?=&]+)(=([^&]*))/g) || []).reduce(
		(a, v) => ((a[v.slice(0, v.indexOf("="))] = v.slice(v.indexOf("=") + 1)), a),
		{}
	);

// 根据?brand_id=或者#?brand_id=进行获取参数
const getBrandId = () => {
  const urlParams = getURLParameters(window.location.search)
  let brandId = urlParams['brand_id']
  console.log("urlParams",brandId, urlParams, )

  if (!brandId) {
    const hashParams = getURLParameters(window.location.hash.slice(1))
    brandId = hashParams['brand_id']
    console.log("hashParams", brandId, hashParams)
  }

  if (!brandId) {
    // 从缓存中获取
    const cachedBrandId = localStorage.getItem('brand_id')
    console.log("cachedBrandId", cachedBrandId)
  }

  return brandId
}

const brandId = getBrandId()
console.log('获取到的 brand_id:', brandId)
if (brandId) {
  localStorage.setItem('brand_id', brandId)
}

// 将 brandId 挂载到 Vue 应用实例上
app.config.globalProperties.$brandId = brandId


// app.use(I18n)
app.use(I18NextVue, { i18next: createI18n() })

// 禁止ios下Safari网页缩放
window.onload = function () {
  let lastTouchEnd = 0
  document.addEventListener('touchstart', (event) => {
    if (event.touches.length > 1)
      event.preventDefault()
  })
  document.addEventListener('touchend', (event) => {
    const now = (new Date()).getTime()
    if (now - lastTouchEnd <= 300)
      event.preventDefault()

    lastTouchEnd = now
  }, false)
  document.addEventListener('gesturestart', (event) => {
    event.preventDefault()
  })
  document.addEventListener('dblclick', (event) => {
    event.preventDefault()
  })
}

// 增强了 Vue Router v4 的滚动行为
app.use(createRouterScroller({
  selectors: {
    'window': true,
    'body': true,
    '.scrollable': true,
  },
}))

app.mount('#app')
