<script setup lang="ts">
import { useTranslation } from "i18next-vue";
import * as i18n from '@/lang/utils'
import { Locale } from 'vant';
// 引入英文语言包
import enUS from 'vant/es/locale/lang/en-US';
import zhCN from 'vant/es/locale/lang/zh-CN';
import zhTW from 'vant/es/locale/lang/zh-TW';
import ko from 'vant/es/locale/lang/ko-KR';
// import { zhCN } from "element-plus/es/locale";

const { i18next, t } = useTranslation();
i18n.set(t);



watch(
	() => i18next.language,
	(val: any) => {
        console.log("val", val);
	},
	{ immediate: true }
);

// function getLocaleName() {
// 	switch (i18next.language) {
// 		case 'zh':
// 		case "zh-CN":
// 			return 'zh-CN';
// 		case "en-US":
// 		case "en":
// 			return 'en-US';
// 		case "es":
// 		case "es-AR":
// 		case "es-BO":
// 		case "es-CL":
// 		case "es-ES":
// 		case "es-MX":
// 		case "es-AR":
// 			return 'es';
// 		case "zh-TW":
// 		case "zh-HK":
// 			return 'zh-TW';
// 		default:
// 			return 'en';
// 	}
// }

function getLocale() {
	switch (i18next.language) {
		case "zh-CN":
			return zhCN;
		case "en":
			return enUS;
		case "es":
		case "es-AR":
		case "es-BO":
		case "es-CL":
		case "es-ES":
		case "es-MX":
		case "es-AR":
			return enUS;
		case "zh-TW":
		case "zh-HK":
			return zhTW;
		case "ko":
			return ko;
		default:
			return enUS;
	}
}


onMounted(() => {
	let obj = filterUrl(window.location.href)
	// console.log(obj)
	let urlLan = obj.lang;
	// 浏览器缓存判断
	let cookieLan = localStorage.getItem("i18nextLng")
	// 浏览器语言判断
	let userLan = navigator.language;
	let lang = urlLan || cookieLan || userLan || "zh-CN";

	Locale.use(lang || i18next.language, getLocale())
})

function filterUrl(url) {
		let filter = url.split("?")[1]?.split("&");
		var obj = {};
		for (var i = 0; i < filter?.length; i++) {
			var item = filter[i].split('=');
			var key = item[0];
			var value = item[1];
			obj[key] = value;
		}
		return obj
	}

</script>

<template>
    <router-view v-slot="{ Component }">
        <Suspense>
            <div>
                <component :is="Component" />
            </div>
        </Suspense>
    </router-view>
</template>
